import { useIsAuthenticatedQuery } from "entities/auth/auth.queries";
import { UserContextParams } from "entities/auth/auth.types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

interface AuthContextProps {
  user: UserContextParams | undefined;
  isUser: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserContextParams | undefined>(undefined);

  const { isAuthenticated, isAuthenticatedLoading, isAuthenticatedSuccess } =
    useIsAuthenticatedQuery(!!user);

  useEffect(() => {
    if (!isAuthenticatedLoading) {
      setUser(isAuthenticated);
    }
  }, []);

  console.log();

  return (
    <AuthContext.Provider
      value={{
        user: user || isAuthenticated,
        isUser: !!user || !!isAuthenticated,
        isLoading: isAuthenticatedLoading,
        isSuccess: isAuthenticatedSuccess
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return useContext(AuthContext);
}
