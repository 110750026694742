import { useMutation, useQuery } from "react-query";
import { useLocalStorage } from "shared/hooks/use-local-storage";
import { isAuthenticated, signin, signout } from "./auth.api";
import { SignInFormParams, UserContextParams } from "./auth.types";

export function useSignInMutation() {
  const { setStorage, removeStorage } = useLocalStorage();
  const { mutateAsync, error, isError, isSuccess, isLoading } = useMutation({
    mutationKey: "signin",
    mutationFn: async ({ email, password }: SignInFormParams) => {
      await signin({ email, password });
    },
    onError: (error: any) => {
      return error?.message;
    },
    onSuccess: (_, { email, rememberMe }) => {
      if (rememberMe) {
        setStorage("rememberedEmail", email);
      } else {
        removeStorage("rememberedEmail");
      }

      window.location.href = "/status";
    }
  });

  return {
    signin: mutateAsync,
    signinError: error?.message ? error.message : undefined,
    isError,
    signinSuccess: isSuccess,
    signinLoading: isLoading
  };
}

export function useIsAuthenticatedQuery(isUser: boolean) {
  const { data, isLoading, isError, isSuccess } = useQuery<UserContextParams>({
    queryKey: ["isAuthenticated"],
    queryFn: async () => await isAuthenticated(),
    enabled: !isUser
  });

  return {
    isAuthenticated: data,
    isAuthenticatedLoading: isLoading,
    isAuthenticatedSuccess: isSuccess,
    isAuthenticatedError: isError
  };
}

export function useSignOutMutation() {
  const { mutateAsync, error, isError, isSuccess, isLoading } = useMutation({
    mutationKey: "signout",
    mutationFn: async () => {
      await signout();
    },
    onError: (error: any) => {
      return error?.message;
    },
    onSuccess: () => {
      window.location.href = "/auth/sign-in";
    }
  });

  return {
    signout: mutateAsync,
    signoutError: error?.message ? error.message : undefined,
    isError,
    signoutSuccess: isSuccess,
    signoutLoading: isLoading
  };
}
