import { useAuthContext } from "app/providers/auth.provider";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import SplashScreen from "shared/components/SplashScreen";
interface PrivateRouteProps {
  children: ReactNode;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { isUser, isLoading, isSuccess } = useAuthContext();

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isUser && !isLoading) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (isSuccess) {
    return children;
  }
}
