import bgImage from "app/assets/images/bg-sign-in-basic.jpeg";
import ErrorPage from "pages/error-page";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./private/PrivateRoute";
import { PublicRoute } from "./public/PublicRoute";

const SignInPage = lazy(() => import("pages/sign-in"));
const StatusPage = lazy(() => import("pages/status"));
const NetworkPage = lazy(() => import("pages/network"));
const NetworkRegisterPage = lazy(() => import("pages/network-register"));
const NetworkEditDeletePage = lazy(() => import("pages/network-edit-delete"));
const StatisticPage = lazy(() => import("pages/statistic/index"));
const BasicLayout = lazy(() => import("shared/components/layouts/BasicLayout"));
const DashboardLayout = lazy(() => import("shared/components/layouts/DashboardLayout"));
const DeviceTypePage = lazy(() => import("pages/device-types"));
const DeviceTypeEditPage = lazy(() => import("pages/device-type-edit"));
const DeviceTypeCreatePage = lazy(() => import("pages/device-type-register"));
const ChangePasswordPage = lazy(() => import("pages/change-password"));
const DevicesPage = lazy(() => import("pages/device"));
const UserPage = lazy(() => import("pages/user"));
const UserCreatePage = lazy(() => import("pages/user-create"));
const DeviceCreatePage = lazy(() => import("pages/device-register"));
const EquipmentListPage = lazy(() => import("pages/equipment"));
const EquipmentRegisterPage = lazy(() => import("pages/equipment-register"));
const EquipmentEditDeletePage = lazy(() => import("pages/equipment-edit-delete"));
const DeviceEditPage = lazy(() => import("pages/device-edit"));
const UserEditPage = lazy(() => import("pages/user-edit"));

export function Router() {
  return (
    <Suspense>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <BasicLayout image={bgImage} />
            </PublicRoute>
          }
        >
          <Route
            path="auth/sign-in"
            element={
              <PublicRoute>
                <SignInPage />
              </PublicRoute>
            }
          />
          <Route
            path="change-password"
            element={
              <PublicRoute>
                <ChangePasswordPage />
              </PublicRoute>
            }
          />
        </Route>

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to="/status" replace />} />
          <Route
            path="status"
            element={
              <PrivateRoute>
                <StatusPage />
              </PrivateRoute>
            }
          />
          <Route
            path="statistic"
            element={
              <PrivateRoute>
                <StatisticPage />
              </PrivateRoute>
            }
          />
          <Route
            path="networks"
            element={
              <PrivateRoute>
                <NetworkPage />
              </PrivateRoute>
            }
          />
          <Route
            path="network/:id"
            element={
              <PrivateRoute>
                <NetworkEditDeletePage />
              </PrivateRoute>
            }
          />

          <Route
            path="network"
            element={
              <PrivateRoute>
                <NetworkRegisterPage />
              </PrivateRoute>
            }
          />

          <Route
            path="device-types"
            element={
              <PrivateRoute>
                <PrivateRoute>
                  <DeviceTypePage />
                </PrivateRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="device-type/:id"
            element={
              <PrivateRoute>
                <PrivateRoute>
                  <DeviceTypeEditPage />
                </PrivateRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="device-type"
            element={
              <PrivateRoute>
                <PrivateRoute>
                  <DeviceTypeCreatePage />
                </PrivateRoute>
              </PrivateRoute>
            }
          />

          <Route
            path="user"
            element={
              <PrivateRoute>
                <UserPage />
              </PrivateRoute>
            }
          />
          <Route
            path="user-create"
            element={
              <PrivateRoute>
                <UserCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="user/:userId"
            element={
              <PrivateRoute>
                <UserEditPage />
              </PrivateRoute>
            }
          />

          <Route
            path="devices"
            element={
              <PrivateRoute>
                <DevicesPage />
              </PrivateRoute>
            }
          />

          <Route
            path="device/:id"
            element={
              <PrivateRoute>
                <DeviceEditPage />
              </PrivateRoute>
            }
          />

          <Route
            path="device"
            element={
              <PrivateRoute>
                <DeviceCreatePage />
              </PrivateRoute>
            }
          />

          <Route
            path="equipments"
            element={
              <PrivateRoute>
                <EquipmentListPage />
              </PrivateRoute>
            }
          />
          <Route
            path="equipment"
            element={
              <PrivateRoute>
                <EquipmentRegisterPage />
              </PrivateRoute>
            }
          />
          <Route
            path="equipment/:id"
            element={
              <PrivateRoute>
                <EquipmentEditDeletePage />
              </PrivateRoute>
            }
          />

          <Route path="teste-error" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
