import { apiInstance } from "shared/api/base";
import { SignInFormParams, UserContextParams } from "./auth.types";

export async function signin({ email, password }: SignInFormParams): Promise<void> {
  await apiInstance.post<void>("/auth/sign-in", {
    email,
    password
  });
}

export async function signout(): Promise<void> {
  await apiInstance.post<void>("/auth/sign-out");
}

export async function isAuthenticated(): Promise<UserContextParams> {
  const response = await apiInstance.get<UserContextParams>("/auth/preload");
  return response.data;
}
