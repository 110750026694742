import { Box, Button, Typography } from "@mui/material";
import imgErrorBoundary from "../../../app/assets/images/img-error-boundary-page.jpg";

export default function ErrorFallback() {
  const pageReload = () => window.location.reload();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      p={2}
    >
      <Box
        component="img"
        src={imgErrorBoundary}
        alt="Error"
        sx={{ maxWidth: "90%", maxHeight: "40vh" }}
      />
      <Typography variant="h5" gutterBottom>
        Ops! Algo deu errado :(
      </Typography>
      <Typography>Por favor, tente novamente.</Typography>
      <Typography>
        Para relatar um problema,{" "}
        <a
          href="https://enablers.allss.com.br/en/helpdesk/tecnologia-da-informacao-4"
          target="_blank"
          rel="noopener noreferrer"
        >
          clique aqui
        </a>
      </Typography>
      <Button variant="contained" onClick={pageReload}>
        RELOAD
      </Button>
    </Box>
  );
}
