export default function ErrorPage() {
  throw new Error("Erro hoje!"); // Simula um erro sendo lançado

  // Aqui você pode retornar o conteúdo da página de erro
  return (
    <div>
      <h1>Erro</h1>
      <p>Algo deu errado.</p>
    </div>
  );
}
