/* eslint-disable camelcase */
import { Grid } from "@mui/material";
import logo_enablers2 from "../../../app/assets/images/logo_enablers2.png";
import "./index.css";

interface SplashScreenProps {
  color?: string;
}

const SplashScreen: React.FC<SplashScreenProps> = () => {
  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <img src={logo_enablers2} alt="Logo da Enablers" className="logo"></img>
      </Grid>
    </div>
  );
};
export default SplashScreen;
