import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const apiInstance = axios.create({
  baseURL: `${API_URL}/v1`,
  withCredentials: true
});

apiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const response = await apiInstance.post("/auth/refresh");
          if (response.status === 204) {
            window.location.href = "/status";
            return axios(error.config);
          }
          if (response.status === 403) {
            window.location.href = "/auth/sign-in";
            return axios(error.config);
          }
        } catch (err: any) {
          throw new Error(error.response.data.message);
        }
      }
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Internal error, contact the support team.");
    }
  }
);

export { apiInstance };
