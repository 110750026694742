import Main from "app/main";
import React from "react";
import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
