import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ErrorFallback from "shared/components/ErrorFallback";
import { MaterialUIControllerProvider } from "shared/libs/mui";
import { queryClient } from "shared/libs/react-query";
import App from "./App";
import { AuthProvider } from "./providers/auth.provider";

export default function Main() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <BrowserRouter>
            <MaterialUIControllerProvider>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
                theme="light"
              />
              <App />
            </MaterialUIControllerProvider>
          </BrowserRouter>
        </AuthProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}
