import { useAuthContext } from "app/providers/auth.provider";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import SplashScreen from "shared/components/SplashScreen";

interface PublicRouteProps {
  children: ReactNode;
}

export function PublicRoute({ children }: PublicRouteProps) {
  const { isUser, isLoading, isSuccess } = useAuthContext();
  console.log({ isUser, isLoading, isSuccess });
  if (isLoading) {
    return <SplashScreen />;
  }

  if (isUser && !isLoading) {
    console.log("Caiu aqui");
    return <Navigate to="/status" replace />;
  }

  return children;
}
