import { toast } from "react-toastify";

export function useLocalStorage() {
  const getStorage = (key: string) => {
    let value = null;

    try {
      const result = window.localStorage.getItem(key);

      if (result) {
        value = JSON.parse(result);
      }
    } catch (error) {
      toast.error(`Error getting item from localStorage`);
    }

    return value;
  };

  const setStorage = (key: string, value: any) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      toast.error(`Error setting item in localStorage`);
    }
  };

  const removeStorage = (key: string) => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      toast.error(`Error removing item from localStorage`);
    }
  };

  return {
    getStorage,
    setStorage,
    removeStorage
  };
}
